const base_path = "/";
const DashboardRoutes = [
  {
    path: base_path,
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "./pages/Index.vue"),
    meta: {
      title: "Dashboard",
    },
  },
];

export default DashboardRoutes;
