<template>
  <validation-input 
    v-slot="{ uuid }" 
    :rules="rules" 
    :label="label"
    :label-horizontal="labelHorizontal">
    <b-switch 
      :id="uuid"
      v-bind="$attrs"
      v-on="$listeners" >
      <slot />
    </b-switch>
  </validation-input>
</template>

<script>
export default {
  model: {
    event: "input",
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    labelHorizontal: {
      type: Boolean,
      default: () => false,
    },
    rules: {
      type: [String, Object],
      default: () => null,
    },
  },
};
</script>
