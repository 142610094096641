<template>
  <validation-input v-slot="{ uuid }" :rules="rules" :label="label">
    <editor
      api-key="eaw7js1xi3vbdjcth3gfbwz7md5k8ujwp42coivv5wk6mcas"
      :init="{
        id: uuid,
        selector: 'textarea',
        language: 'es',
        height: 250,
        menubar,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help'
      }"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </validation-input>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
export default {
  components: {
    Editor
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: () => null,
    },
    menubar: {
      type: Boolean,
      default: () => false,
    }
  },
}
</script>