import { clientAxios } from "../../plugins/axios";

export const csrf = () => {
  return clientAxios.get(`sanctum/csrf-cookie`);
}

export const SingInService = (payload = {}) => {
  return clientAxios.post(`/auth/singin`, payload);
};

export const EditProfileSingInService = (payload = {}) => {
  return clientAxios.put(`/auth/profile`, payload);
};

export const UserService = () => {
  const token_auth = window.localStorage.getItem("token_auth");
  if (token_auth) return clientAxios.get(`/auth/user`);
  return Promise.reject();
};

export const LogsService = () => {
  const token_auth = window.localStorage.getItem("token_auth");
  if (token_auth) return clientAxios.get(`/auth/user/logs`);
  return Promise.reject();
};

export const LogoutService = () => {
  const token_auth = window.localStorage.getItem("token_auth");
  if (token_auth) return clientAxios.get(`/auth/logout`);
  return Promise.resolve();
};
