<template>
  <div>
    <div ref="chart"></div>
    <p v-if="loading" class="p-4">
      <b-skeleton active></b-skeleton>
      <b-skeleton height="120px"></b-skeleton>
    </p>
    <div v-if="!loading" class="table-container pb-1 pr-1">
      <table class="table is-fullwidth is-size-7">
        <tfoot>
          <tr>
            <th class="py-0 m-0" :width="widths[0]"></th>
            <th class="py-0 m-0 has-text-right" :width="widths[1]">
              TOTAL
            </th>
            <th class="py-0 m-0 has-text-right" v-for="(i,key) in totales" :key="key" :width="widths[key+2]">
              {{i}}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
import {GoogleCharts} from 'google-charts';
import { parseDate,isNumeric } from '@/utils/helpers';
export default {
  props: {
    results:{
      type: Array,
      default: () => []
    },
    fullHeight: {
      type: Boolean,
      default: () => false
    },
    selection: {
      type: Object,
      default: () => null,
    },
  },
  data(){
    return {
      loading: true,
      isDate: false,
      totales: [],
      widths: [0,0],
    }
  },
  computed:{
    rows(){
      return this.results.map(e => { 
        if(typeof(e[0]) === "object" && e[0].type === "date") this.isDate = true
        if(typeof(e[0]) === "string" && this.isDate) e[0] = parseDate(e[0],"-")
        return e
      }) 
    }
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        if(!this.$refs.chart) {
          this.loading = false
          return;
        }
        GoogleCharts.load(this.drawChart)
        this.loading = false
      })
    }, 500)
  },
  methods: {
    drawChart(){
      const data = new GoogleCharts.api.visualization.DataTable();
      const [a = [],...b] = this.rows
      const {fila,columna} = this.selection || {}
      let index_fila = null
      let index_columna = null
      for (const [i,e] of a.entries()) {
        data.addColumn(e.type, e.label);
        if(columna && e.label === columna) {
          index_columna = i
        }
      }
      for (const e of b) {
        for (let index = 0; index < e.length; index++) {
          if(fila && index === 0 && e[index] === fila){
            index_fila = index;
          }
          if(index > 0){
            if(a[index].isPercent){
              this.totales[index-1] = 100
            } else {
              if(this.totales.length >= index){
                this.totales[index-1] += e[index]
              } else {
                this.totales[index-1] = e[index]
              }
            }
          }
        }
      }
      this.totales = this.totales.map(e => e.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
      // 
      data.addRows(b);
      const options = {
        showRowNumber: true, 
        width: this.$refs.chart.offsetWidth - 5, 
        height: this.fullHeight ? window.innerHeight * 0.75 : 350,
        frozenColumns: a.length > 10 ? 1 : null,
        // sortColumn: 1,
        sortAscending: false,
      }
      const formatter = new GoogleCharts.api.visualization.NumberFormat({ 
        prefix: '',decimalSymbol: '.', groupingSymbol: ',' 
      });
      const formatterPercent = new GoogleCharts.api.visualization.NumberFormat({ 
        suffix: '%',decimalSymbol: '.', groupingSymbol: ',',
      });
      for (let i = 1; i <= a.length-1; i++) {
        if(a[i].isPercent){
          formatterPercent.format(data, i);
        } else {
          formatter.format(data, i);
        }
      }
      
      const chart = new GoogleCharts.api.visualization.Table(this.$refs.chart);
      
      GoogleCharts.api.visualization.events.addListener(chart, 'ready', () => {
        if(isNumeric(index_fila) || isNumeric(index_columna)){
          chart.setSelection([{row: index_fila, column: index_columna}])
        }
        this.widths = []
        Array.from(this.$refs.chart.querySelectorAll(`th.google-visualization-table-th`))
          .map(e => this.widths.push(e.offsetWidth * 0.96))
      });
      GoogleCharts.api.visualization.events.addListener(chart, 'select', () => {
        const selection = chart.getSelection()
        let fila = null
        let columna = null
        if(selection.length){
          const item = selection[0]
          const cell = window.event.target;
          if(cell.cellIndex > 1){
            columna = a[cell.cellIndex - 1].label
          }
          if(isNumeric(item.row)) fila = data.getFormattedValue(item.row, 0)
        }
        this.$emit("onselect", {fila,columna})
      })
      // 
      chart.draw(data, options);
    },

  }
}
</script>