export default () => ({
  isOpenFilter: false,
  pagination: {},
  filter: {},
  rows: [],
  all_rows: [],
  favorites: [],
  rowEdit: null,
  loading: {},
  modal: {},
  selected: {},
  request: {},
});
