import { DashboardService } from "../services";

export default {
  async dashboardRequest({commit}, payload){
    try {
      const data = await DashboardService(payload);
      commit('form_1617124496702/SET_ROW', data, {root:true});
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e)
    }
  },
};
