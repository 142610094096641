import { PaginateService, DeleteService, GetService, PostService, PutService } from "../services";
import {setUriParams} from "@/utils/helpers"

export default {
  async paginate({commit, getters}, {url, params = {}} = {}){
    try {
      commit("SET_FILTER", params);
      setUriParams(getters.filter)
      const {data, ...p} = await PaginateService(url, getters.filter);
      commit("SET_ROWS", data)
      commit("SET_PAGINATION", p)
      return Promise.resolve();
    } catch (e) {
      commit("SET_ROWS")
      return Promise.resolve();
    }
  },
  async get({commit, getters}, payload){
    try {
      if(getters.row && Number(getters.row.id) === Number(payload)) {
        return Promise.resolve(getters.row);
      }
      const data = await GetService(payload);
      commit("SET_ROW", data)
      return Promise.resolve(data);
    } catch (e) {
      commit("SET_ROW")
      return Promise.resolve(null);
    }
  },
  async save({commit}, payload){
    try {
      const $http = !payload.id ? PostService : PutService
      const data = await $http(payload);
      commit("SET_UPDATE_ROW", data)
      return Promise.resolve(data);
    } catch (e) {
      return Promise.resolve(null);
    }
  },
  async destroy(ctx, payload){
    try {
      const data = await DeleteService(payload)
      return Promise.resolve(data)
    } catch (e) {
      return Promise.resolve(null)
    }
  }
};
