const base_path = "entitie";
const Routes = [
  {
    path: `/${base_path}/config`,
    name: "EntitieConfig",
    component: () =>
    import("./pages/Config.vue"),
    meta: {
      title: "Configuración",
    },
  },
  {
    path: `/${base_path}/variables`,
    name: "EntitieVariablesFormulas",
    component: () =>
    import("./pages/Variables.vue"),
    meta: {
      title: "Variables y fórmulas",
    },
  },
];

export default Routes;
