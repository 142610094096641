export default {
  setLoadingPage({ commit }, payload) {
    commit("SET_LOADING_PAGE", payload);
  },
  setToastError({ commit }, payload) {
    commit("SET_TOAST", {
      type: "is-danger",
      message: payload,
      position: "is-bottom",
      duration: 10000,
    });
  },
  setToastWarning({ commit }, payload) {
    commit("SET_TOAST", {
      type: "is-warning",
      message: payload,
      position: "is-bottom-right",
      duration: 8000,
    });
  },
  setToastSuccess({ commit }, payload) {
    commit("SET_TOAST", {
      type: "is-success",
      message: payload,
      position: "is-top-right",
      duration: 10000,
    });
  },
  setShowMenu({ commit }, payload) {
    commit("SET_SHOW_MENU", payload);
  },
  SetInputSearchMenu({ commit }, payload) {
    commit("SET_INPUT_SEARCH_MENU", payload);
  },
  set404({commit}, payload = false){
    commit("SET_404", payload)
  }
};
