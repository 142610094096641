import Vue from "vue";
import Vuex from "vuex";
//
import app from "./app";

Vue.use(Vuex);

const modules = {}
const files = require.context("@/modules", true, /store\/index.js$/i);
files.keys().map(e => {
  const r = e.split("/")
  r.splice(0,1)
  r.splice(-2,2)
  const module = (r.join("_")).toLowerCase()
  modules[module] = files(e).default
  modules[module].namespaced = true
})

export default new Vuex.Store({
  modules: {
    app,
    ...modules,
  },
});
