export default {
  SET_ROWS(state, payload){
    state.rows = payload || []
  },
  SET_PAGINATION(state, payload){
    state.pagination = payload
  },
  SET_FILTER(state, payload = {}){
    state.filter = {...state.filter, ...payload}
  },
  SET_IS_OPEN_FILTER(state, payload){
    state.isOpenFilter = payload
  }
};
