import Vue from "vue";
import Buefy from "buefy";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
//
import "./plugins/axios";
import "./plugins/fontawesome";
import "./plugins/vee-validate";
import "./plugins/uuid";
import "./plugins/portal-component";
import "./plugins/vue-select";
import "./plugins/components";
import "./registerServiceWorker";
//
Vue.config.productionTip = false;

Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
