<template>
  <validation-input v-slot="{ uuid }" :rules="rules" :label="label">
    <b-input
      :id="uuid"
      v-bind="$attrs"
      v-on="$listeners"
      expanded
      lazy
      maxlength="7"
      v-model="color"
      v-show="showInput"
      :size="size"
    />
    <p class="control">
      <span :class="`button ${size}`"><input type="color" v-model="color" /></span>
    </p>
  </validation-input>
</template>

<script>
export default {
  model: {
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: () => null,
    },
    label: {
      type: String,
      default: () => "",
    },
    rules: {
      type: [String, Object],
      default: () => null,
    },
    showInput: {
      type: Boolean,
      default: () => true,
    },
    size: {
      type: String,
      default: () => ``,
    }
  },
  data() {
    return {
      cache: "",
    };
  },
  computed: {
    color: {
      get() {
        return this.value || "#000000";
      },
      set(e) {
        if (e && e.trim().length === 7) {
          this.$emit("input", e);
          this.cache = e;
        } else {
          this.$emit("input", this.cache);
        }
      },
    },
  },
  mounted() {
    this.cache = this.color;
  },
};
</script>
