<template>
  <validation-input v-slot="{ uuid }" :rules="rules" :label="label">
    <b-autocomplete :id="uuid" v-bind="$attrs" v-on="$listeners">
      <template v-if="showEmpty" #empty>No hay resultados</template>
      <template v-if="showAdd" #footer>
        <a @click.prevent="() => $emit(`onAddNew`)">
          <span>Agregar nuevo</span>
        </a>
      </template>
      <template v-for="slot in scopedSlots" #[slot]>
        <slot :name="slot" />
      </template>
    </b-autocomplete>
  </validation-input>
</template>

<script>
export default {
  model: {
    event: "input",
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: () => null,
    },
    showAdd: {
      type: Boolean,
      default: () => false,
    },
    showEmpty: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    scopedSlots() {
      return Object.keys(this.$scopedSlots);
    },
  },
};
</script>
