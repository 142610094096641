import { clientAxios } from "../../plugins/axios";

const base = `negocios`

export const GetService = () => {
  return clientAxios.get(base);
}

export const SaveConfigService = (payload) => {
  return clientAxios.put(`configuracion`, payload);
}

export const GetConfigService = () => {
  return clientAxios.get(`configuracion/base`);
}

export const SaveCamposDinamicosService = (payload = {}) => {
  return clientAxios.post(`configuracion/campos/dinamicos`, payload);
}

export const DeleteCamposDinamicosService = (payload) => {
  return clientAxios.delete(`configuracion/campos/dinamicos/${payload}`);
}

export const SaveService = (payload = {}) => {
  return clientAxios.put(base, payload);
};
