<template>
  <b-field :label="inline ? label : ``">
    <b-dropdown
        v-model="selectedOptions"
        :inline="inline"
        multiple
        scrollable
        aria-role="list"
        max-height="200px"
        trap-focus
        expanded
        v-bind="$attrs"
        @active-change="handleActiveChange"
        @change="handleChange">
      <template #trigger="{ active }">
        <b-button
          expanded
          type="is-secondary"
          class="is-justify-content-space-between"
          :icon-right="active ? 'caret-up' : 'caret-down'">
          {{label}} ({{ selectedOptions.length ? selectedOptions.length : `Todos` }})
        </b-button>
      </template>
      <b-dropdown-item custom aria-role="listitem" class="px-2">
        <b-input 
          v-model="search" 
          placeholder="Escriba para buscar" 
          size="is-small"
          ref="input" 
          expanded />
      </b-dropdown-item>
      <b-dropdown-item v-show="!loading" custom aria-role="listitem" >
        <b-field>
          <b-checkbox v-model="checkAll">Todos</b-checkbox>
        </b-field>
      </b-dropdown-item>
      <b-dropdown-item :value="row.id" aria-role="listitem" v-for="row in rows" :key="row.id">
        {{row.value}}
      </b-dropdown-item>
      <b-dropdown-item v-show="loading" custom aria-role="listitem" >
        <b-skeleton animated></b-skeleton>
      </b-dropdown-item>
      <b-dropdown-item v-show="loading" custom aria-role="listitem" >
        <b-skeleton animated></b-skeleton>
      </b-dropdown-item>
      <b-dropdown-item v-show="loading" custom aria-role="listitem" >
        <b-skeleton animated></b-skeleton>
      </b-dropdown-item>
    </b-dropdown>
  </b-field>
</template>
<script>
import { clientAxios } from '@/plugins/axios'
export default {
  props: {
    label:{
      type: String,
      default: () => "",
    },
    uri: {
      type: String,
      default: () => null,
    },
    value: {
      type: Array,
      default: () => []
    },
    inline:{
      type: Boolean,
      default: () => false,
    }
  },  
  data(){
    return {
      search: "",
      rows: [],
      loading: true,
      checkAll: true,
      selectedOptions: this.value,
    }
  },
  watch:{
    search(){
      this.handleSearch()
    },
    checkAll(e){
      if(e) {
        this.selectedOptions = []
        if(this.inline) this.$emit("change", [])
      }
    }
  },
  created() {
    this.setCheckAll()
  },
  mounted() {
    if(this.inline){
      this.handleActiveChange(true)
    }
  },
  methods: {
    handleActiveChange(e){
      if(e){
        this.$refs.input.focus()
        this.handleSearch()
      } else {
        this.$emit("input", this.selectedOptions)
        this.$emit("change", this.selectedOptions)
      }
    },
    handleChange(e){
      if(!e.length){
        this.checkAll = true
      } else {
        this.checkAll = false
      }
      if(this.inline) this.$emit("change", e)
    },
    async handleSearch() {
      this.loading = true
      this.rows = await clientAxios.get(this.uri ,{params:{search: this.search}})
      this.loading = false
    },
    setCheckAll(){
      if(this.selectedOptions.length) this.checkAll = false
      else this.checkAll = true
    }
  },
}
</script>