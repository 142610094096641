import BeforeMiddleware from "./middleware/BeforeMiddleware";

const base_path = "share";
const AuthRoutes = [
  {
    path: `/${base_path}/:code`,
    name: "ShareLink",
    component: () =>
      import(/* webpackChunkName: "share-index" */ "./pages/Index.vue"),
    meta: {
      title: "Dashboard",
      auth: false,
      beforeEnter: BeforeMiddleware,
    },
  }
];

export default AuthRoutes;
