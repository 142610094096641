export default {
  SET_ROWS(state, payload){
    state.rows = payload || []
  },
  SET_ROW(state, payload){
    state.row = payload || null
  },
  SET_ALL_ROWS(state, payload){
    state.all_rows = payload || []
  },
  SET_UPDATE_ROW_ALL_ROWS(state, payload){
    const index = state.all_rows.findIndex(e => e.id === payload.id)
    if(index >=0 ){
      state.all_rows.splice(index, 1, payload)
    }
  },
  SET_PAGINATION(state, payload){
    state.pagination = payload
  },
  SET_FILTER(state, payload = {}){
    state.filter = {...state.filter, ...payload}
  },
  SET_IS_OPEN_FILTER(state, payload){
    state.isOpenFilter = payload
  },
  SET_LOADING(state, payload){
    state.loading = {...state.loading, ...payload}
  },
  SET_DATE_FILTER(state, payload){
    state.date_filter = payload || []
  },
  SET_WATCH_FILTER(state){
    state.watchFilter = (new Date).getTime()
  },
  SET_MULTI_FILTER(state, payload){
    if(!payload) {
      state.multiFilter = []
      return
    }
    const {id, ...e} = payload
    const index = state.multiFilter.findIndex(e => e.id === id)
    if(index >= 0){
      state.multiFilter.splice(index, 1, {id, ...e})
    } else {
      state.multiFilter.push({id, ...e})
    }
    if(!state.multiFilter.filter(e => e.fila || e.columna).length){
      state.multiFilter = []
    }
  },
  SET_SLIDER_ITEMS_CACHE(state, payload){
    state.sliderItemsCache = payload ? {...state.sliderItemsCache, ...payload} : {}
  },
};
