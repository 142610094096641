<template>
  <b-dropdown
    aria-role="list"
    ref="dropdown"
    v-model="navigation"
    @change="handleChange"
    v-bind="$attrs"
  >
    <template #trigger="{ active }">
      <b-button
        :type="`is-${theme}`"
        :size="size"
        :icon-right="active ? 'caret-up' : 'caret-down'"
        icon-left="calendar"
      >
        <span v-if="date1 && date2">
          <strong v-if="label">{{ label }}:</strong> {{ date1 }} - {{ date2 }}
        </span>
        <span v-else>{{placeholder}}</span>
      </b-button>
    </template>

    <b-dropdown-item value="1" aria-role="listitem">Hoy</b-dropdown-item>
    <b-dropdown-item value="2" aria-role="listitem">Ayer</b-dropdown-item>
    <b-dropdown-item value="3" aria-role="listitem"
      >Últimos 7 días</b-dropdown-item
    >
    <b-dropdown-item value="4" aria-role="listitem">Este mes</b-dropdown-item>
    <b-dropdown-item value="5" aria-role="listitem"
      >Mes anterior</b-dropdown-item
    >
    <b-dropdown-item value="6" aria-role="listitem">Limpiar</b-dropdown-item>
    <b-dropdown-item value="7" custom aria-role="listitem">
      <date-picker-base
        expanded
        size="is-small"
        range
        position="is-bottom-left"
        icon="calendar"
        v-model="dates"
        :mobile-native="false"
        @input="handleChangeDate"
      />
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { addSubstractDays, formatDate, isNumeric, timestampToDate } from "@/utils/helpers";
export default {
  props: {
    value: {
      type: [String,Array],
      default: () => [],
    },
    theme: {
      type: String,
      default: () => "primary",
    },
    label: {
      type: String,
      default: () => null,
    },
    placeholder: {
      type: String,
      default: () => null,
    },
    size: {
      type: String,
      default: () => `is-small`
    }
  },
  data() {
    return {
      navigation: null,
    };
  },
  computed: {
    dates: {
      get() {
        return (this.value || []).map(e => {
          if (isNumeric(e)) {
            return timestampToDate(e)
          }
          return e
        });
      },
      set(e) {
        this.$emit("input", e);
        this.$emit("change", e);
      },
    },
    date1() {
      if(this.dates.length) return formatDate(this.dates[0]);
      return null
    },
    date2() {
      if(this.dates.length === 2) return formatDate(this.dates[1]);
      return null;
    },
  },
  methods: {
    handleChange(e) {
      switch (e) {
        case "1":
          return this.handleNow();
        case "2":
          return this.handleYesterday();
        case "3":
          return this.handleLast7Days();
        case "4":
          return this.handleThisMonth();
        case "5":
          return this.handleLastMonth();
        case "6":
          return this.handleClear();
        default:
          break;
      }
    },
    handleNow() {
      this.dates = [new Date(), new Date()];
    },
    handleYesterday() {
      const d1 = new Date();
      const d2 = addSubstractDays(d1, -1);
      this.dates = [d2, d2];
    },
    handleLast7Days() {
      const d1 = new Date();
      const d2 = addSubstractDays(d1, -7);
      this.dates = [d2, d1];
    },
    handleThisMonth() {
      const now = new Date();
      const d1 = new Date(now.getFullYear(), now.getMonth(), 1);
      const d2 = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      this.dates = [d1, d2];
    },
    handleLastMonth() {
      const now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() - 1;
      if (month === -1) {
        year--;
        month = 11;
      }
      const d1 = new Date(year, month, 1);
      const d2 = new Date(year, month + 1, 0);
      this.dates = [d1, d2];
    },
    handleClear(){
      this.dates = [];
    },
    handleChangeDate() {
      this.navigation = "7";
      this.$refs.dropdown.toggle();
    },
  },
};
</script>
