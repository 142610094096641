<template>
  <component id="app" :is="layout">
    <router-view />
    <toast-alert />
    <portal-target name="modals"></portal-target>
  </component>
</template>

<script>
import ToastAlert from "@/components/ToastAlert.vue";
const defaultLayout = `default`;
export default {
  components: {
    ToastAlert,
  },
  computed: {
    layout() {
      const layout = this.$route.meta.layout || defaultLayout;
      return () => import(`@/layouts/${layout}.vue`);
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/app";
</style>
