import { clientAxios } from "../../../plugins/axios";

const base = "dashboards/panels"

export const AllService = (params = []) => {
  return clientAxios.get(`/${base}`, {params});
};

export const PaginateService = (params = []) => {
  return clientAxios.get(`/paginate/${base}`, {params});
};

export const GetService = (payload = null, params = {}) => {
  return clientAxios.get(`/${base}/${payload}`, {params});
};

export const CubeService = (payload = null, params = {}) => {
  return clientAxios.post(`/${base}/cube/${payload}`, params);
};

export const PostService = (payload = {}) => {
  return clientAxios.post(`/${base}/`, payload);
};

export const PutService = ({id, ...payload}) => {
  return clientAxios.put(`/${base}/${id}`, payload);
};

export const MoveService = ({id, move}) => {
  return clientAxios.put(`/${base}/move/${id}`, {move});
};

export const DeleteService = (payload = null) => {
  return clientAxios.delete(`/${base}/${payload}`);
};

export const ExportExcelService = ({id, codigo}) => {
  return `${process.env.VUE_APP_APIURL}/${base}/excel/${codigo}/${id}`;
};