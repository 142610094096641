export default {
  rows: state => state.rows,
  all_rows: state => state.all_rows,
  pagination: state => state.pagination,
  filter: state => state.filter,
  isOpenFilter: state => state.isOpenFilter,
  row: state => state.row,
  loading: state => state.loading,
  modal: state => state.modal,
  rowEdit: state => state.rowEdit,
  title_bar(state, getters){
    return rr => {
      const dashboard = getters.row
      const to_last = rr ? `/form/1623469803663/${dashboard.id}` : ``
      const ii = [
        {
          title: 'Informes', to: `/form/1617206897966` 
        },
        {
          title: dashboard.categoria.nombre, to: `/form/1617206897966` 
        },
        {
          title: dashboard.nombre, to: to_last
        },
      ];
      if(dashboard.descripcion) {
        ii[2].title += ` - ${dashboard.descripcion}`
      }
      if(rr) ii.push(rr)
      return ii 
    }
  }
};
