export default {
  empresa: (state) => state.empresa,
  config: (state) => state.config,
  nombre_comercial: (state, getters) => getters.empresa && getters.empresa.nombre_comercial,
  sitio_web: (state, getters) => getters.empresa && getters.empresa.sitio_web,
  logo_url: (state, getters) => getters.empresa && getters.empresa.logo_url,
  icon_url: (state, getters) => {
    const icons = getters.config && getters.config.icons && getters.config.icons.attrs
    if(icons){
      const e = icons.find(e => e.size === "original")
      return e ? process.env.VUE_APP_STORAGEURL + e.src : null
    }
    return null
  },
};
