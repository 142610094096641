export const isMobile = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const treeToArray = (
  items = [],
  keyTree = "menus",
  keyTitle = "title",
  search = "",
  where = ""
) => {
  const load = (items = [], parent = null) => {
    return items.map(t =>{
      const e = {...t}
      if(!e.full_name) e.full_name = e[keyTitle];
      // 
      if(parent){
        e.full_name = `${parent.full_name} / ${e.full_name}`
      }
      e[keyTree] = load(e[keyTree] || [], e)
      return e
    })
  }
  const getItems = (items = [], keyTree = "", n_items = []) => {
    for (const item of items) {
      const data = { ...item };
      const tree = data[keyTree] || [];
      delete data[keyTree];
      n_items.push(data);
      getItems(tree, keyTree, n_items);
    }
    return n_items;
  };
  return getItems( load(items) , keyTree).filter(
    (e) =>
      e.full_name.toLowerCase().indexOf(search.toLowerCase()) >= 0 &&
      (where ? e[where] : true)
  );
};

export const addSubstractDays = (date = new Date(), numberofDays = 0) => {
  let d = new Date(date);
  const time = d.setDate(d.getDate() + numberofDays);
  return new Date(time);
};

export const dateToTimestamp = (date = new Date()) => date instanceof Date ? Math.floor(date.getTime() / 1000) : date;
export const timestampToDate = (time) => new Date(time * 1000)

export const formatDate = (date = new Date(), withTime = false) => {
  if (date instanceof Date) {
    const minutes = `${date.getMinutes()}`.padStart(2, 0)
    const hours = date.getHours()
    const hour = `${hours > 12 ? hours - 12 : hours}`.padStart(2, 0)
    const am_pm = hours >= 12 ? `pm` : `am`
    //
    const day = `${date.getDate()}`.padStart(2, 0)
    const month = `${date.getMonth() + 1}`.padStart(2, 0)
    const year = date.getFullYear()
    let str_date = `${day}/${month}/${year}`
    if (withTime) {
      str_date += ` ${hour}:${minutes} ${am_pm}`
    }
    return str_date
  }
  return "";
};

export const parseDate = (date = "", format = "/") => {
  try {
    if (typeof(date) === "string") {
      const d = date.split(format);
      if (d.length === 3) {
        if(format === "/"){
          const e = new Date(d[2], d[1] - 1, d[0]);
          if(e instanceof Date && !isNaN(e)) return e
        } else {
          const e = new Date(d[0], d[1] - 1, d[2]);
          if(e instanceof Date && !isNaN(e)) return e
        }
      }
    }
    return null;
  } catch (e) {
    return null
  }
};

export const nameForm = ctx => {
  const r = ctx.keys()
  return r.length ? r[0].replace("./","").replace(".name","") : ""
}

export const nameStoreForm = ctx => {
  const f = nameForm(ctx)
  return `form_${f}`
}

export const getQueryUrl = () => {
  const e = {}
  const _url = new URL(window.location)
  for (const pair of _url.searchParams.entries()) {
    e[pair[0]] = pair[1]
  }
  if(e.created_range) e.created_range = e.created_range.split(",")
  return e
}

export const getQueryForPagination = (url, p) => {
  let params = p ? {...p} : null
  if(!params || !Object.keys(params).length){
    params = getQueryUrl()
  }
  if(!url && params && params.current_page) {
    params.page = params.current_page
  }
  if(url && params.page){
    delete(params.page)
  }
  delete(params.current_page)
  return params
}

export const searchTreeNode = (nodeName, nodeValue, rootNode, childrenKey = 'menus') => {
  let stack = [];
  stack.push(rootNode);
  while (stack.length) {
    let node = stack.pop();
    if (nodeValue && node[nodeName] && nodeValue.includes(node[nodeName])) {
      return node;
    } else if (node[childrenKey] && node[childrenKey].length) {
      for (let ii = 0; ii < node[childrenKey].length; ii += 1) {
        stack.push(node.menus[ii]);
      }
    }
  }
  return null;
}

export const setUriParams = (obj) => {
  if(!obj || !Object.keys(obj).length) return null;
  // 
  const url = new URL(window.location)
  for (const key in obj) {
    url.searchParams.set(key, obj[key])
  }
  window.history.pushState({}, '', url)
}

export const configUploadProgress = (cb) => {
  return {
    onUploadProgress: (p) => {
      if(p.total > 100){
        const percent = Math.round((p.loaded * 100) / p.total)
        cb( percent );
        if(percent === 100) setTimeout(() => cb(0), 1000)
      }
    }
  }
}

export const isObject = (object) => object != null && typeof object === 'object';

export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

export const deepEqual = (object1, object2) => {
  if(!object1 || !object2) return false;
  // 
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }
  
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }
  return true;
}