
const base_path = `/form/1617206897966/`;
const FormRoutes = [
  {
    path: base_path,
    name: 'form_1617206897966',
    component: () => import("./pages/Index.vue"),
    meta: {
      title: "Informes",
    },
  },
  {
    path: `${base_path}nuevo`,
    name: `informe-nuevo`,
    component: () => import("./pages/Nuevo.vue"),
    meta: {
      title: "Nuevo",
    },
  },
];

export default FormRoutes;
