<template>
  <select-search-base
    v-bind="$attrs"
    v-on="$listeners"
    :options="rows"
    v-model="id"
    @search="request"
    @search:focus="focus_request"
  />
</template>
<script>
import { clientAxios } from '../../plugins/axios'
export default {
  props:{
    value:{
      type: [String,Number],
      default: () => null
    },
    uri:{
      type: String,
      default: () => null
    }
  },
  data(){
    return {
      rows: [],
      tmp: null,
    }
  },
  watch:{
    id:{
      async handler(e){
        const f = this.rows.find(r => r.id === e)
        if(e && !f){
          this.rows = await clientAxios.get(this.uri ,{params:{id : e}})
        }
      },
      immediate: true,
    }
  },
  computed:{
    id:{
      get(){
        const e = this.tmp ? this.tmp : this.value
        return e && !isNaN(e) ? Number(e) : e
      },
      set(e){
        this.tmp = e
        this.$emit("input", e)
        this.$emit("change", e)
      }
    }
  },
  methods:{
    async request(search = "", loading = null){
      try {
        if(typeof search === "string") search = search.trim()
        // 
        if(loading) loading(true)
        if(this.uri && search.length){
          this.rows = await clientAxios.get(this.uri ,{params:{search}})
          if(!this.rows.length) this.id = null
        }
        if(loading) loading(false)
      } catch (error) {
        this.rows = [];
        this.id = null
        if(loading) loading(false)
      }
    },
    async focus_request(){
      if(!this.rows.length){
        this.rows = await clientAxios.get(this.uri ,{params:{search:""}})
      }
    }
  }
}
</script>