<template>
  <div></div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      status: null,
    };
  },
  computed: {
    ...mapGetters({
      toast: "app/toast",
    }),
  },
  watch: {
    toast(ee) {
      this.close()
      if(ee){
        const {duration, ...e} = ee
        e.indefinite = true
        e.queue = false
        this.status = this.$buefy.toast.open(e);
        setTimeout(this.close, duration)
      }
    },
  },
  methods:{
    close(){
      if(this.status){
        this.status.close();
        this.status = null;
      }
    }
  }
};
</script>
