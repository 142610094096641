<template>
  <b-modal
    v-model="show"
    v-bind="$attrs"
    v-on="$listeners"
    has-modal-card
    aria-role="dialog"
    aria-modal
  >
    <template #default="props">
      <div class="modal-card">
        <header v-if="$slots.header || title" class="modal-card-head">
          <slot name="header">
            <p class="modal-card-title">{{ title }}</p>
          </slot>
        </header>
        <section class="modal-card-body">
          <slot v-bind="props"></slot>
        </section>
        <footer
          v-if="$slots.footer"
          class="modal-card-foot pull-right"
          style="justify-content: flex-end"
        >
          <slot name="footer" v-bind="props"></slot>
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => null,
    },
    value: {
      type: Boolean,
      defualt: () => false,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit("input", e);
      },
    },
  },
};
</script>
