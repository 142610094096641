export default [
  {
    title: 'Inicio',
    icon: 'home',
    path: '/'
  },
  // {
  //   title: 'Almacén de datos',
  //   icon: 'database',
  //   path: '/form/1623939727455'
  // },
];
