<template>
  <b-button
    type="is-secondary"
    icon-left="broom"
    size="is-small"
    @click="handleClear"
    ><slot>Limpiar</slot></b-button
  >
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    form:{
      get(){
        return this.value
      },
      set(e){
        this.$emit("input", e)
      }
    },
    name_store(){
      return `form_${this.$route.meta.form}`
    },
  },
  methods:{
    handleClear(){
      const not = ["per_page","current_page"]
      for (const key in this.form) {
        if(!not.includes(key)){
          this.$set(this.form, key, "")
        }
      }
      this.$emit("clear")
    }
  }
}
</script>