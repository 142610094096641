import { PaginateService, AllService, DeleteService, GetService, PostService, PutService, FavoritesService } from "../services";
import {setUriParams} from "@/utils/helpers"

export default {
  async all({commit, getters}, payload = false){
    try {
      if(getters.request.all && !payload) return Promise.resolve()
      // 
      commit("SET_LOADING", {all: true})
      const data = await AllService();
      commit("SET_ALL_ROWS", data)
      commit("SET_LOADING", {all: false})
      commit("SET_REQUEST", {all: true})
      return Promise.resolve();
    } catch (e) {
      commit("SET_LOADING", {all: false})
      commit("SET_REQUEST", {all: false})
      commit("SET_ALL_ROWS")
      return Promise.resolve();
    }
  },
  async paginate({commit, getters}, {url, params = {}} = {}){
    try {
      commit("SET_LOADING", {paginate: true})
      commit("SET_FILTER", params);
      setUriParams(getters.filter)
      const {data, ...p} = await PaginateService(url, getters.filter);
      commit("SET_ROWS", data)
      commit("SET_PAGINATION", p)
      commit("SET_LOADING", {paginate: false})
      return Promise.resolve();
    } catch (e) {
      commit("SET_ROWS")
      commit("SET_LOADING", {paginate: false})
      return Promise.resolve();
    }
  },
  async favorites({commit}){
    try {
      commit("SET_LOADING", {favorites: true})
      const data = await FavoritesService();
      commit("SET_FAVORITES", data)
      commit("SET_LOADING", {favorites: false})
      return Promise.resolve();
    } catch (e) {
      commit("SET_FAVORITES")
      commit("SET_LOADING", {favorites: false})
      return Promise.resolve();
    }
  },
  async get(e, payload){
    try {
      const data = await GetService(payload);
      return Promise.resolve(data);
    } catch (e) {
      return Promise.resolve(null);
    }
  },
  async save({commit,dispatch}, payload){
    try {
      const $http = !payload.id ? PostService : PutService
      const data = await $http(payload);
      commit("SET_SELECTED",{for_dash: [data]})
      dispatch("favorites", true)
      return Promise.resolve(data);
    } catch (e) {
      return Promise.resolve(null);
    }
  },
  async destroy(ctx, payload){
    try {
      const data = await DeleteService(payload)
      // const [r] = getters.getSelected("for_dash")
      // if(r.id === data.id) commit("SET_SELECTED",{for_dash: []})
      return Promise.resolve(data)
    } catch (e) {
      return Promise.resolve(null)
    }
  }
};
