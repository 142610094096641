import menu_app from "@/store/menu";
import { treeToArray } from "../../utils/helpers";
export default {
  toast: (state) => state.toast,
  loading_page: (state) => state.loading_page,
  show_menu: (state) => state.show_menu,
  input_search_menu: (state) => state.input_search_menu,
  is404: (state) => state.is404,
  menu: (state, getters, rootState, rootGetters) => {
    return menu_app.concat(rootGetters["form_1617206897966/menu_rows"])
  },
  menu_search(state, getters) {
    return treeToArray(
      getters.menu,
      "menus",
      "title",
      getters.input_search_menu,
      "path"
    );
  },
};
