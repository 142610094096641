export default {
  SET_ROWS(state, payload){
    state.rows = payload || []
  },
  SET_ALL_ROWS(state, payload){
    state.all_rows = payload || []
  },
  SET_PAGINATION(state, payload){
    state.pagination = payload
  },
  SET_FILTER(state, payload = {}){
    state.filter = {...state.filter, ...payload}
  },
  SET_IS_OPEN_FILTER(state, payload){
    state.isOpenFilter = payload
  },
  SET_LOADING(state, payload = {}){
    state.loading = {...state.loading, ...payload}
  },
  SET_MODAL(state, payload = {}){
    state.modal = {...state.modal, ...payload}
  },
  SET_ROW(state, payload = null){
    state.row = payload
  },
  SET_UPDATE_ROW(state, payload = null){
    if(state.row && state.row.id === payload.id){
      state.row = {...state.row, ...payload}
    }
  },
  SET_ROW_EDIT(state, payload = null){
    state.rowEdit = payload ? {...payload} : null
  },
};
