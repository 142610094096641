import { csrf, LogoutService, SingInService, UserService, EditProfileSingInService, LogsService } from "../services";

export default {
  async csrf({commit, getters}){
    try {
      if(getters.csrf) return Promise.resolve()
      commit("SET_CSRF")
      return csrf()
    } catch (e) {
      return Promise.resolve()
    }
  },
  async SignInRequest({ commit }, payload) {
    try {
      const { token_auth, ...data } = await SingInService(payload);
      commit("SET_TOKEN", token_auth);
      commit("SET_USER", data);
      return Promise.resolve(data);
    } catch (e) {
      return Promise.resolve(null);
    }
  },
  async UserRequest({ commit, getters }) {
    try {
      if (getters.user) return Promise.resolve(getters.user);
      //
      const data = await UserService();
      commit("SET_USER", data);
      return Promise.resolve(data);
    } catch (e) {
      commit("SET_TOKEN");
      commit("SET_USER");
      return Promise.resolve(null);
    }
  },
  async LogsRequest({ commit }) {
    try {
      const data = await LogsService();
      commit("SET_LOGS", data);
      return Promise.resolve(data);
    } catch (e) {
      commit("SET_LOGS");
      return Promise.resolve([]);
    }
  },
  async LogoutRequest({ commit }) {
    try {
      await LogoutService();
      commit("SET_TOKEN");
      commit("SET_USER");
      commit("entitie/SET_NEGOCIO",null,{root: true});
      return Promise.resolve();
    } catch (e) {
      commit("SET_TOKEN");
      commit("SET_USER");
      commit("entitie/SET_NEGOCIO",null,{root: true});
      return Promise.resolve();
    }
  },
  clearAuth({commit}){
    commit("SET_TOKEN");
    commit("SET_USER");
    commit("entitie/SET_NEGOCIO",null,{root: true});
    return Promise.resolve();
  },
  async ProfileRequest({commit}, payload) {
    try {
      const data = await EditProfileSingInService(payload);
      commit("SET_USER", data);
      return Promise.resolve(data);
    } catch (e) {
      return Promise.resolve(null);
    }
  },
};
