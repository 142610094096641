import { nameForm } from "../../../utils/helpers";
import BeforeMiddleware from "./middleware/BeforeMiddleware";
const form = nameForm( require.context("./", false, /\.name$/i) );

const base_path = `/form/${form}/:id/`;
const FormRoutes = [
  {
    path: `${base_path}`,
    name: form,
    component: () => import("./pages/Index.vue"),
    meta: {
      title: "Dashboard",
      form,
      beforeEnter: BeforeMiddleware,
    },
  },
  {
    path: `${base_path}nuevo/grafico`,
    name: `${form}-nuevo-grafico`,
    component: () => import("./pages/NuevoGrafico.vue"),
    meta: {
      title: "Gráfico",
      form,
      beforeEnter: BeforeMiddleware
    },
  },
  {
    path: `${base_path}nuevo/filtro`,
    name: `${form}-nuevo-filtro`,
    component: () => import("./pages/NuevoFiltro.vue"),
    meta: {
      title: "Filtro",
      form,
      beforeEnter: BeforeMiddleware
    },
  },
  {
    path: `${base_path}nuevo/medidor`,
    name: `${form}-nuevo-medidor`,
    component: () => import("./pages/NuevoMedidor.vue"),
    meta: {
      title: "Medidor",
      form,
      beforeEnter: BeforeMiddleware
    },
  },
  {
    path: `${base_path}nuevo/numero`,
    name: `${form}-nuevo-numero`,
    component: () => import("./pages/NuevoNumero.vue"),
    meta: {
      title: "Número",
      form,
      beforeEnter: BeforeMiddleware
    },
  },
];

export default FormRoutes;
