import store from "@/store"

export default async (to, from, next) => {
  try {
    const {id} = to.query
    await store.dispatch("form_1617124496702/get", to.params.id)
    if(id) {
      await store.dispatch(`form_1623469803663/get`, id)
    } else {
      store.commit(`form_1623469803663/SET_ROW`)
    }
    next()
  } catch (error) {
    next("/")
  }
}