<template>
  <validation-input v-slot="{ uuid }" :rules="rules" :label="label">
    <b-field class="file is-secondary" :class="{ 'has-name': !!file }">
      <b-upload
        :id="uuid"
        v-bind="$attrs"
        v-on="$listeners"
        v-model="file"
      >
        <slot>
          <span class="file-cta">
            <b-icon class="file-icon" icon="upload"></b-icon>
            <span class="file-label">Clic para cargar un archivo</span>
          </span>
          <span class="file-name" v-if="file">
            {{ file.name }}
          </span>
        </slot>
      </b-upload>
      <b-button
        v-if="$attrs['drag-drop'] === undefined && file"
        icon-right="times"
        title="Limpiar campo"
        @click.prevent="() => (file = null)"
      ></b-button>
    </b-field>
    <div 
      v-if="$attrs['drag-drop'] !== undefined && file && file.length" 
      class="tags are-medium">
      <span v-for="(f, index) in file" :key="index" class="tag is-primary is-light">
        {{f.name}}
        <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
      </span>
    </div>
    <b-progress
      v-if="progress && progress <= 100"
      type="is-success"
      :max="100"
      :value="progress"
      show-value
      format="percent"></b-progress>
  </validation-input>
</template>

<script>
export default {
  props: {
    value: {
      type: [File, FileList, Array],
      default: () => null,
    },
    label: {
      type: String,
      default: () => "",
    },
    rules: {
      type: [String, Object],
      default: () => null,
    },
    progress: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    file:{
      get(){
        return this.value || null
      },
      set(e){
        this.$emit("input", e)
      }
    }
  },
  methods: {
    deleteDropFile(index) {
      this.file.splice(index, 1);
    }
  }
};
</script>
