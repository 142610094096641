import { nameForm } from "@/utils/helpers";
const code_form = nameForm( require.context("../", false, /\.name$/i) );
// 
export default {
  rows: state => state.rows,
  row: state => state.row,
  all_rows: state => state.all_rows,
  pagination: state => state.pagination,
  filter: state => state.filter,
  isOpenFilter: state => state.isOpenFilter,
  loading: state => state.loading,
  date_filter: state => state.date_filter,
  watchFilter: state => state.watchFilter,
  multiFilter: state => state.multiFilter,
  sliderItemsCacheKey: state => key => state.sliderItemsCache[key] || null,
  multiFilterRequest: (state,getters) => getters.multiFilter.filter(e => e.fila || e.columna),
  toGrafico: () => id => ({name: `${code_form}-nuevo-grafico`, params: {id}}),
  toMedidor: () => id => ({name: `${code_form}-nuevo-medidor`, params: {id}}),
  toNumero: () => id => ({name: `${code_form}-nuevo-numero`, params: {id}}),
  toFiltro: () => id => ({name: `${code_form}-nuevo-filtro`, params: {id}}),
  anchosBloque(){
    return [
      {id: 1, value: "Pequeño"},
      {id: 2, value: "Mediano"},
      {id: 3, value: "Grande"},
    ]
  },
  tiposGraficos(){
    return [
      {id: 1, value: "Area"},
      {id: 2, value: "Barras"},
      {id: 3, value: "Columnas"},
      {id: 4, value: "Lineas"},
      {id: 5, value: "Pie"},
      {id: 6, value: "Tabla"},
    ]
  },
  campos_dinamicos(state, getters, rootState, rootGetters){
    let e = rootGetters[`entitie/config`].campos_dinamicos
    return [...((e && e.attrs) || [])]
  },
  filtros_dinamicos(state, getters, rootState, rootGetters){
    let e = rootGetters[`entitie/config`].filtros_dinamicos
    return [...((e && e.attrs) || [])]
  },
  findRow: (state, getters) => id => getters.all_rows.find(e => e.id === id)
};
