var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-input',{attrs:{"rules":_vm.rules,"label":_vm.label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var uuid = ref.uuid;
return [_c('editor',_vm._g(_vm._b({attrs:{"api-key":"eaw7js1xi3vbdjcth3gfbwz7md5k8ujwp42coivv5wk6mcas","init":{
      id: uuid,
      selector: 'textarea',
      language: 'es',
      height: 250,
      menubar: _vm.menubar,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | help'
    }}},'editor',_vm.$attrs,false),_vm.$listeners))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }