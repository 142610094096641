const base_path = "/users";
const UsersRoutes = [
  {
    path: `${base_path}`,
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "./pages/Index.vue"),
    meta: {
      title: "Usuarios",
    },
  },
  {
    path: `${base_path}/nuevo`,
    name: `users-nuevo`,
    component: () => import("./pages/Nuevo.vue"),
    meta: {
      title: "Usuario",
    },
  },
  {
    path: `${base_path}/roles/:id`,
    name: `users-roles`,
    component: () => import("./pages/Roles.vue"),
    meta: {
      title: "Roles del Usuario",
    },
  },
];

export default UsersRoutes;
