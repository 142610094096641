<template>
  <validation-input v-slot="{ uuid, errors }" :rules="rules" :label="label">
    <div :class="!errors[0] ? `` : ``">
      <v-select
        v-bind="$attrs"
        v-on="$listeners"
        :label="labelSelect"
        :options="opts"
        :input-id="uuid"
        :reduce="(c) => c.id"
        :placeholder="placeholder"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            No se encontraron resultados para <em>{{ search }}</em
            >.
          </template>
          <em style="opacity: 0.5" v-else
            >Escriba para realizar una búsqueda.</em
          >
        </template>
      </v-select>
    </div>
  </validation-input>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => "",
    },
    labelSelect: {
      type: String,
      default: () => "value",
    },
    rules: {
      type: [String, Object],
      default: () => null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: () => "Seleccione una opción",
    },
  },
  computed: {
    opts() {
      if (this.options.length) {
        const isObject = this.options[0] instanceof Object;
        if (!isObject) return this.options.map((e) => ({ id: e, value: e }));
      }
      return this.options;
    },
  },
};
</script>
