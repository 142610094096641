<template>
  <div class="card is-card-widget">
    <header class="card-header">
      <div class="card-header-title">
        <b-icon v-if="iconTitle" :icon="iconTitle" />
        <slot name="title"></slot>
      </div>
      <div class="card-header-icon">
      <slot name="title-button"></slot>
      </div>
    </header>
    <div :class="`card-content ${classContent}`">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    iconTitle:{
      type: String,
      default: () => `chevron-right`
    },
    classContent:{
      type: String,
      default: () => ``
    }
  }
}
</script>
<style lang="scss" scoped>
.card.is-card-widget .card-header,
.card.has-card-header-background .card-header {
  border-bottom: 0;
}
.card-header:first-child,
.card-content:first-child,
.card-footer:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card.is-card-widget .card-header p {
  font-size: 0.85rem;
  line-height: 0.85rem;
  padding: 0.495rem;
}
.card.is-card-widget .card-header p,
.card.has-card-header-background .card-header p {
  font-weight: normal;
}
.card.is-card-widget .card-header .button {
  margin-right: 0.495rem;
}
.card .card-header .button {
  display: inline-flex;
  align-self: center;
  margin-right: 0.75rem;
}
</style>
