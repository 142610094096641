<template>
  <validation-input v-slot="{ uuid, validate }" :rules="rules" :label="label">
    <div class="content">
      <img :src="url_img" ref="img" :id="uuid" @click="handleFile" />
      <div
        :class="`bt has-background-${theme} reset`"
        @click="handleReset(validate)"
      >
        <b-icon icon="times" />
      </div>
      <input
        type="file"
        ref="input"
        accept="image/*"
        @change="(e) => handleOnChangeFile(e, validate)"
      />
      <p class="is-size-7 m-0">{{ height }} x {{ width }}</p>
      <input type="hidden" v-model="file" />
    </div>
  </validation-input>
</template>
<script>
export default {
  props: {
    value:{
      type: [File, FileList],
      default: () => null
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: () => null,
    },
    src: {
      type: String,
      default: () => null,
    },
    height: {
      type: String,
      default: () => "auto",
    },
    width: {
      type: String,
      default: () => "auto",
    },
    theme: {
      type: String,
      default: () => "primary",
    },
  },
  data() {
    return {
      isRemove: false,
    }
  },
  watch:{
    file(e){
      if(!e){
        this.$refs.input.value = null;
        this.handleShowImg();
      }
    }
  },
  computed: {
    url_img() {
      if(this.src && !this.isRemove) return this.src;
      return require("@/assets/images/no-photo.png");
    },
    file:{
      get(){
        return this.value
      },
      set(e){
        this.$emit("input", e);
      }
    }
  },
  methods: {
    handleReset(validate) {
      this.file = null;
      this.$refs.input.value = null;
      this.isRemove = true
      this.$emit("remove");
      this.handleShowImg();
      validate(null);
    },
    handleFile() {
      this.$refs.input.click();
    },
    handleOnChangeFile(e, validate) {
      const { files } = e.target;
      let f = null
      if (files.length) {
        f = files[0];
      } else {
        f = null;
      }
      this.file = f;
      this.isRemove = false
      this.handleShowImg(f);
      validate(f);
    },
    handleShowImg(file = null) {
      const $el = this.$refs.img;
      if (FileReader && file) {
        const fr = new FileReader();
        fr.onload = function () {
          $el.src = fr.result;
        };
        fr.readAsDataURL(file);
      } else {
        $el.src = this.url_img;
      }
    },
  },
};
</script>
<style scoped>
.content {
  border: 1px solid #bbb;
  margin: 0 0 0.5rem 0;
  padding: 0.2rem 0.2rem 0 0.2rem;
  display: inline-block;
  position: relative;
  text-align: center;
}
.bt {
  padding: 0;
  margin: 0;
  position: absolute;
  color: white;
  cursor: pointer;
}
.edit {
  top: 0;
  left: 0;
}
.reset {
  top: 0;
  right: 0;
}
img {
  height: 75px;
  margin: auto;
  cursor: pointer;
}
input {
  display: none;
}
</style>