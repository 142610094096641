import { PaginateService, DeleteService, GetService, PostService, PutService } from "../services";
import {setUriParams} from "@/utils/helpers"

export default {
  async paginate({commit, getters}, {url, params={}} = {}){
    try {
      commit("SET_FILTER", params);
      setUriParams(getters.filter);
      const {data, ...p} = await PaginateService(url, getters.filter);
      commit("SET_ROWS", data)
      commit("SET_PAGINATION", p)
      return Promise.resolve();
    } catch (e) {
      commit("SET_ROWS")
      return Promise.resolve();
    }
  },
  async get(e, payload){
    try {
      const data = await GetService(payload);
      return Promise.resolve(data);
    } catch (e) {
      return Promise.resolve(null);
    }
  },
  async save(e, payload){
    try {
      const $http = !payload.id ? PostService : PutService
      const data = await $http(payload);
      return Promise.resolve(data);
    } catch (e) {
      return Promise.resolve(null);
    }
  },
  async destroy(e, payload){
    try {
      const data = await DeleteService(payload)
      return Promise.resolve(data)
    } catch (e) {
      return Promise.resolve(null)
    }
  }
};
