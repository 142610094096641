export default {
  rows: state => state.rows,
  pagination: state => state.pagination,
  filter: state => state.filter,
  isOpenFilter: state => state.isOpenFilter,
  row: state => state.row,
  loading: state => state.loading,
  modal: state => state.modal,
  rowEdit: state => state.rowEdit,
};
