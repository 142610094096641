<template>
  <validation-provider
    v-slot="{ errors, validate }"
    :rules="rules"
    :name="label ? label : ``"
  >
    <b-field
      custom-class="has-text-weight-semibold"
      :label-for="uuid"
      :type="errors[0] ? `is-danger` : ``"
      :message="errors[0]"
      :horizontal="labelHorizontal"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #label>
        {{label}}
        <span class="has-text-danger" v-if="isRequired">*</span>
      </template>
      <slot v-bind="{ validate, uuid, errors }"></slot>
    </b-field>
    <div v-if="errors[0]" class="pt-1"></div>
  </validation-provider>
</template>

<script>
export default {
  props: {
    rules: {
      type: [String, Object],
      default: () => null,
    },
    label: {
      type: String,
      default: () => null,
    },
    labelHorizontal: {
      type: Boolean,
      default: () => false,
    }
  },
  computed: {
    uuid() {
      return `input-${this.$uuid()}`;
    },
    isRequired() {
      if(this.rules){
        if(typeof(this.rules) === "string"){
          return this.rules.includes("required")
        }
        if(typeof(this.rules) === "object"){
          return this.rules.required === true
        }
      }
      return false
    }
  },
};
</script>
