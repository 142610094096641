import { clientAxios } from "@/plugins/axios";
import { AllService, DeleteService, GetService, PostService, PutService, MoveService, CubeService } from "../services";

export default {
  async all({commit}, params = {}){
    try {
      commit("SET_LOADING", {all: true})
      const data = await AllService(params);
      commit("SET_ALL_ROWS", data)
      commit("SET_LOADING", {all: false})
      return Promise.resolve();
    } catch (e) {
      commit("SET_ALL_ROWS")
      commit("SET_LOADING", {all: false})
      return Promise.resolve();
    }
  },
  async get({commit}, payload){
    try {
      const data = await GetService(payload);
      commit("SET_ROW", data)
      return Promise.resolve(data);
    } catch (e) {
      commit("SET_ROW")
      return Promise.reject(e);
    }
  },
  async getCubeSql({commit, getters}, payload){
    try {
      const panel = getters.findRow(payload)
      if(panel && !panel.attrs.filtros_externos){
        return Promise.resolve(true)
      }
      const e = getters.multiFilterRequest.find(e => e.id === payload)
      if(e && getters.multiFilter.length === 1) {
        return Promise.resolve(true)
      }
      // 
      commit("SET_LOADING", {[`cube_${payload}`]: true})
      let data = null
      if(getters.multiFilterRequest.length){
        data = await CubeService(payload, {filters: getters.multiFilterRequest});
      } else {
        data = await GetService(payload, {cube: true});
      }
      commit("SET_UPDATE_ROW_ALL_ROWS", data)
      commit("SET_LOADING", {[`cube_${payload}`]: false})
      return Promise.resolve(data);
    } catch (e) {
      commit("SET_LOADING", {[`cube_${payload}`]: false})
      return Promise.resolve(null);
    }
  },
  async save(e, payload){
    try {
      const $http = !payload.id ? PostService : PutService
      const data = await $http(payload);
      return Promise.resolve(data);
    } catch (e) {
      return Promise.resolve(null);
    }
  },
  async move({dispatch}, payload){
    try {
      const data = await MoveService(payload);
      if(data){
        dispatch("all", {id_padre: data.id_padre})
      }
      return Promise.resolve(data);
    } catch (e) {
      return Promise.resolve(null);
    }
  },
  async destroy({dispatch}, payload){
    try {
      const data = await DeleteService(payload)
      dispatch("all", {id_padre: data.id_padre})
      return Promise.resolve(data)
    } catch (e) {
      return Promise.resolve(null)
    }
  },
  async requestSliderCache({commit, getters}, {key,uri}){
    try {
      const rows = getters.sliderItemsCacheKey(key)
      if(rows) return Promise.resolve(rows)
      // 
      const data = await clientAxios.get(uri)
      commit("SET_SLIDER_ITEMS_CACHE", {[key]: data})
      return Promise.resolve(data)
    } catch (e) {
      return Promise.resolve([])
    }
  },
};
