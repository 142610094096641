import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import AuthMiddleware from "../middlewares/AuthMiddleware";

Vue.use(VueRouter);

let routes = []
const files = require.context("@/modules", true, /\utes.js$/i);
files.keys().map(e => {
  routes = routes.concat(files(e).default)
})

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(AuthMiddleware);

router.afterEach(() => {
  store.dispatch("app/set404", false)
  store.dispatch("app/setLoadingPage", false)
});

export default router;
