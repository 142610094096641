export default {
  rows: state => state.rows,
  all_rows: state => state.all_rows,
  favorites: state => state.favorites,
  menu_rows: (state, getters) => {
    return getters.all_rows.map(e => ({
      title: e.nombre,
      icon: 'chevron-right',
      menus: e.dashboards.map(r => ({
        title: r.nombre,
        path: `/form/1623469803663/${r.id}`
      }))
    }))
  },
  pagination: state => state.pagination,
  filter: state => state.filter,
  isOpenFilter: state => state.isOpenFilter,
  loading: state => state.loading,
  modal: state => state.modal,
  rowEdit: state => state.rowEdit,
  selected: state => state.selected,
  request: state => state.request,
  getSelected: (state, getters) => {
    return key => {
      const s = getters.selected[key] || []
      const r = getters.all_rows.map(e => e.id)
      return s.filter(e => r.includes(e.id))
    }
  },
};
