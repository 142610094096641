import {dateToTimestamp} from "@/utils/helpers"
import $resizeImage from "@/utils/resizeImage"

export default (data) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    if (!data) return resolve(data)
    //
    let isInputFile = false
    let formData = new FormData()

    for await (const key of Object.keys(data)) {
      if (typeof data[key] === "string") {
        data[key] = data[key].trim()
      }

      if (data[key] instanceof Date) {
        data[key] = dateToTimestamp(data[key])
      }

      if (Array.isArray(data[key])) {
        data[key] = data[key].map((e) => {
          if (e instanceof Date) {
            e = dateToTimestamp(e)
          } else {
            if (typeof e === "object") {
              Object.keys(e).map((k) => {
                if (e[k] instanceof Date) e[k] = dateToTimestamp(e[k])
              })
            }
          }
          return e
        })
      }

      if (formData) {
        if (
          data[key] instanceof File ||
          data[key] instanceof FileList ||
          (data[key] instanceof Array &&
            data[key].length > 0 &&
            data[key][0] instanceof File)
        ) {
          isInputFile = true

          if (data[key] instanceof File) {
            const blob = await $resizeImage(data[key])
            formData.append(key, blob, blob.name || "file.jpg")
          } else {
            let i = 0
            for await (const file of [...data[key]]) {
              const blob = await $resizeImage(file)
              formData.set(`${key}[${i}]`, blob, blob.name || `file-${i}.jpg`)
              i++
            }
          }
        } else {
          let dato = data[key]
          if (!dato) dato = ""

          if (Array.isArray(dato) || dato instanceof Object)
            formData.append(key, JSON.stringify(dato))
          else {
            if(dato === true || dato === false){
              formData.append(key, dato === true ? `1` : `0`)
            } else {
              formData.append(key, dato)
            }
          }
        }
      }
    }

    if (isInputFile && formData) return resolve(formData)
    return resolve(data)
  })
}