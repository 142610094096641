<template>
  <div class="card">
    <header :class="`card-header has-background-${theme}`">
      <p class="card-header-title has-text-white">
        <slot name="title"></slot>
      </p>
    </header>
    <div class="card-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: () => "primary",
    },
  },
};
</script>
