import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";

for (let rule in rules) {
  extend(rule, {
    ...rules[rule],
    message: es.messages[rule],
  });
}

extend("decimal", {
  validate: (value, { decimals = "*", separator = "." } = {}) => {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
    );
    return {
      valid: regex.test(value),
    };
  },
  message: `El campo {_field_} debe ser un número`,
});

Vue.component("validation-observer", ValidationObserver);
Vue.component("validation-provider", ValidationProvider);
