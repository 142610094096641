<template>
  <validation-observer
    ref="observer"
    tag="form"
    autocomplete="off"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </validation-observer>
</template>

<script>
export default {
  methods: {
    validate() {
      return this.$refs.observer.validate();
    },
    reset() {
      return this.$refs.observer.reset();
    },
  },
};
</script>
