<template>
  <validation-input v-slot="{ uuid }" :rules="rules" :label="label">
    <div class="block" :id="uuid">
      <b-radio
        :name="uuid"
        v-bind="options.$attrs || {}"
        v-on="$listeners"
        v-for="option in options"
        :native-value="option.id"
        :key="option.id"
        v-model="item"
      >
        {{ option.value }}
      </b-radio>
    </div>
  </validation-input>
</template>

<script>
export default {
  model: {
    event: "input",
  },
  props: {
    value: {
      type: [String,Number,Boolean],
      default: () => null
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: () => null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed:{
    item:{
      get(){
        return this.value
      },
      set(e){
        this.$emit("input",e)
      }
    }
  }
};
</script>
