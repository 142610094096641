import Vue from "vue";
import draggable from 'vuedraggable'

const files = require.context("@/components/globals", true, /\.vue$/i);
files.keys().map((key) =>
  Vue.component(
    key
      .split("/")
      .pop()
      .split(".")[0],
    files(key).default
  )
);

Vue.component("draggable", draggable)