import store from "@/store"

export default async (to, from, next) => {
  try {
    const {nombre,descripcion} = await store.dispatch("share/dashboardRequest", to.params.code)
    document.title = `${nombre} ${descripcion ? ` - ${descripcion}` : ``} - ${process.env.VUE_APP_NAME}`
    next()
  } catch (error) {
    next("/")
  }
}