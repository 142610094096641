<template>
  <validation-input v-slot="{ uuid }" :rules="rules" :label="label">
    <b-datetimepicker 
      :id="uuid" 
      v-bind="$attrs" 
      v-on="$listeners" 
      locale="es-ES" 
      v-model="fecha"
      :placeholder="placeholder"
      horizontal-time-picker
      :timepicker="timepicker" />
  </validation-input>
</template>

<script>
import { isNumeric } from '@/utils/helpers';
export default {
  model: {
    event: "input",
  },
  props: {
    value: {
      type: [Number, Date],
      default: () => null,
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: () => null,
    },
    placeholder: {
      type: String,
      default: () => `Seleccione una fecha`,
    }
  },
  data(){
    return {
      timepicker:{
        hourFormat: "12",
      }
    }
  },
  computed: {
    fecha:{
      get(){
        if(isNumeric(this.value)) return new Date(this.value * 1000)
        return this.value
      },
      set(e){
        this.$emit("input", e)
      }
    },
  }
};
</script>
