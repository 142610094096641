export default {
  SET_NEGOCIO(state, payload = null) {
    state.empresa = payload ? payload : {};
  },
  SET_CONFIG(state, payload = {}) {
    state.config = payload ? {...state.config, ...payload} : {};
  },
  SET_CAMPOS_DINAMICOS(state, payload = []) {
    if(state.config && state.config.campos_dinamicos){
      state.config.campos_dinamicos.attrs = payload
    }
  },
};
