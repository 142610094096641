<template>
  <div>
    <div ref="chart"></div>
    <p v-if="loading" class="p-4">
      <b-skeleton active></b-skeleton>
      <b-skeleton height="120px"></b-skeleton>
    </p>
  </div>
</template>
<script>
import {GoogleCharts} from 'google-charts';
import { isNumeric } from '@/utils/helpers';
export default {
  props: {
    results:{
      type: Array,
      default: () => []
    },
    fullHeight: {
      type: Boolean,
      default: () => false
    },
    selection: {
      type: Object,
      default: () => null,
    }
  },
  data(){
    return {
      loading: true,
      isDate: false,
    }
  },
  computed:{
    rows(){
      return this.results.map(e => {
        if(typeof(e[0]) === "object" && e[0].type === "date") e[0].type = "string"
        if(e[1] < 0) e[1] = 0;
        if(isNumeric(e[1])) e[1] = parseFloat(e[1].toFixed(2))
        return e
      }) 
    },
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        if(!this.$refs.chart) {
          this.loading = false
          return;
        }
        GoogleCharts.load(this.drawChart)
        this.loading = false
      })
    }, 500)
  },
  methods: {
    drawChart(){
      const {fila} = this.selection || {}
      let index_fila = null
      for (const [i,e] of this.rows.entries()) {
        if(i === 0) continue;
        for (let index = 0; index < e.length; index++) {
          if(fila && index === 0 && e[index] === fila){
            index_fila = index;
          }
        }
      }
      const data = GoogleCharts.api.visualization.arrayToDataTable(this.rows);
      const options = {
        legend: { 
          textStyle: {
            fontSize: 14,
            color: '#6E768F',
          },
        },
        height: this.fullHeight ? window.innerHeight * 0.75 : 300,
        chartArea: {
          left: 20,
          right: 30,
          top: 40,
          height: "80%",
          width: "100%",
        },
        tooltip: {
          showColorCode: true,
          text: 'value',
          trigger: 'selection'
        },
        fontSize: '12',
        sliceVisibilityThreshold:0.00,
        is3D: true,
      }
      const chart = new GoogleCharts.api.visualization.PieChart(this.$refs.chart);
      // 
      GoogleCharts.api.visualization.events.addListener(chart, 'select', () => {
        const selection = chart.getSelection()
        let fila = null
        let columna = null
        if(selection.length){
          const item = selection[0]
          if(isNumeric(item.row)) fila = data.getFormattedValue(item.row, 0)
        }
        this.$emit("onselect", {fila,columna})
      })
      GoogleCharts.api.visualization.events.addListener(chart, 'ready', () => {
        if(isNumeric(index_fila)){
          chart.setSelection([{row: index_fila}])
        }
        let imgUri = chart.getImageURI();
        imgUri = imgUri.replace(/^data:image\/png/, 'data:application/octet-stream');
        this.$emit('onImageUri', imgUri);
      });
      // 
      chart.draw(data, options);
    }
  }
}
</script>