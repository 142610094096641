<template>
  <validation-input v-slot="{ uuid, errors }" :rules="rules" :label="label" :label-horizontal="labelHorizontal">
    <div :class="!errors[0] ? `` : ``">
      <b-select v-bind="$attrs" v-on="$listeners" :id="uuid">
        <option value="" disabled v-if="!isEmptyVal">{{ placeholder }}</option>
        <option v-for="option in opts" :value="option.id" :key="option.id">
          {{ option.value }}
        </option>
      </b-select>
    </div>
  </validation-input>
</template>

<script>
export default {
  model: {
    event: "input",
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: () => null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: () => "Seleccione una opción",
    },
    labelHorizontal: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    opts() {
      if (this.options.length) {
        const isObject = this.options[0] instanceof Object;
        if (!isObject) return this.options.map((e) => ({ id: e, value: e }));
      }
      return this.options;
    },
    isEmptyVal(){
      return this.opts.find(e => !e.id)
    }
  },
};
</script>
