export default () => ({
  isOpenFilter: false,
  pagination: {},
  filter: {},
  rows: [],
  row: null,
  all_rows: [],
  loading: {},
  date_filter: [new Date, new Date],
  watchFilter: null,
  multiFilter: [],
  sliderItemsCache: [],
});
