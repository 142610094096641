import { nameForm } from "../../../utils/helpers";
const form = nameForm( require.context("./", false, /\.name$/i) );

const base_path = `/form/${form}/`;
const FormRoutes = [
  {
    path: base_path,
    name: form,
    component: () => import("./pages/Index.vue"),
    meta: {
      title: "Negocios",
      form,
    },
  },
  {
    path: `${base_path}nuevo`,
    name: `${form}-nuevo`,
    component: () => import("./pages/Nuevo.vue"),
    meta: {
      title: "Nuevo negocio",
      form,
    },
  },
];

export default FormRoutes;
