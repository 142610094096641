export default {
  SET_TOAST(state, payload) {
    state.toast = payload || {};
  },
  SET_LOADING_PAGE(state, payload) {
    state.loading_page = payload || false;
  },
  SET_SHOW_MENU(state, payload) {
    state.show_menu = payload || false;
  },
  SET_INPUT_SEARCH_MENU(state, payload) {
    state.input_search_menu = payload || "";
  },
  SET_404(state, payload){
    state.is404 = payload
  }
};
