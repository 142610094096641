<template>
  <validation-input v-slot="{ uuid }" :rules="rules" :label="label">
    <b-datepicker 
      :id="uuid" 
      v-bind="$attrs" 
      v-on="$listeners" 
      locale="es-ES" 
      v-model="fecha"
      :placeholder="txt_placeholder" />
  </validation-input>
</template>

<script>
import {timestampToDate, isNumeric} from "@/utils/helpers"
export default {
  model: {
    event: "input",
  },
  props: {
    value: {
      type: [Array, Date],
      default: () => null,
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: () => null,
    },
    placeholder: {
      type: String,
      default: () => null,
    }
  },
  computed: {
    fecha:{
      get(){
        if(isNumeric(this.value)) return timestampToDate(this.value)
        if(this.value instanceof Array) {
          let items = [...this.value]
          for (const [o,i] of items.entries()) {
            if (isNumeric(i)) {
              items[o] = timestampToDate(i)
            }
          }
          return items
        }
        return this.value
      },
      set(e){
        this.$emit("input", e)
      }
    },
    txt_placeholder(){
      if(this.placeholder) return this.placeholder
      return this.$attrs.range !== undefined ? "Selecciona un rango de fechas" : `Seleccione una fecha`
    }
  }
};
</script>
