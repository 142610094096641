<template>
  <validation-input v-slot="{ uuid }" :rules="rules" :label="label" :label-horizontal="labelHorizontal">
    <b-input :id="uuid" :type="type" v-bind="$attrs" v-on="$listeners" ref="input" />
  </validation-input>
</template>

<script>
export default {
  model: {
    event: "input",
  },
  props: {
    type: {
      type: String,
      default: "text",
      validator(value) {
        return [
          "email",
          "number",
          "password",
          "search",
          "tel",
          "text",
          "url",
          "textarea",
        ].includes(value);
      },
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: () => null,
    },
    labelHorizontal: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    if(this.$attrs.focus !== undefined && this.$attrs.focus !== null){
      this.$refs[`input`].focus();
    }
  }
};
</script>
