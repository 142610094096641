const ErrorRoutes = [
  {
    path: `*`,
    name: "Error404",
    component: () =>
      import(/* webpackChunkName: "404" */ "./pages/404.vue"),
    meta: {
      title: "Error 404",
    },
  },
];

export default ErrorRoutes;
