<template>
  <validation-input v-slot="{ uuid }" :rules="rules" :label="label">
    <div class="block" :id="uuid">
      <div v-if="block">
        <b-field v-for="item in options" :key="item.id">
          <b-checkbox :native-value="item.id" v-bind="$attrs" v-on="$listeners">
            {{ item.value }}
          </b-checkbox>
        </b-field>
      </div>
      <div v-else>
        <b-checkbox
          v-bind="$attrs"
          v-on="$listeners"
          v-for="option in options"
          :native-value="option.id"
          :key="option.id"
        >
          {{ option.value }}
        </b-checkbox>
      </div>
    </div>
  </validation-input>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: () => null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    block: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    if (!this.$attrs.value && this.options.length > 1) this.$emit("input", []);
  },
};
</script>
