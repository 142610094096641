import { clientAxios } from "../../../plugins/axios";
import { getQueryForPagination } from "../../../utils/helpers";

const base = "negocios"

export const PaginateService = (url = null, params = {}) => {
  return clientAxios.get(url ? url : `/${base}/paginate/`, {params: getQueryForPagination(url, params)});
};

export const GetService = (payload = null) => {
  return clientAxios.get(`/${base}/${payload}`);
};

export const PostService = (payload = {}) => {
  return clientAxios.post(`/${base}/`, payload);
};

export const PutService = ({id, ...payload}) => {
  return clientAxios.put(`/${base}/${id}`, payload);
};

export const DeleteService = (payload = null) => {
  return clientAxios.delete(`/${base}/${payload}`);
};
