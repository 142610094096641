import store from "@/store";
//
export default async (to, from, next) => {
  //
  store.dispatch("app/setLoadingPage", true);
  // 
  await store.dispatch("auth/csrf")
  //
  if (to.meta.title) {
    document.title = `${to.meta.title} - ${process.env.VUE_APP_NAME}`;
  }
  //
  await store.dispatch("entitie/getRequest")
  await store.dispatch("auth/UserRequest");
  const isAuthenticated = store.getters["auth/user"] !== null;
  //
  if(isAuthenticated){
    await store.dispatch("entitie/getConfigRequest")
    await store.dispatch("form_1617206897966/all")
  }
  // 
  const is_auth = to.meta.auth === undefined ? true : false
  // 
  if (to.name !== "AuthSignIn" && !isAuthenticated && is_auth)
    next({ name: "AuthSignIn" });
  else if (to.name === "AuthSignIn" && isAuthenticated && is_auth)
    next({ name: "Dashboard" });
  else {
    if(to.meta.beforeEnter){
      to.meta.beforeEnter(to, from, next);
    } else {
      next();
    }
  }
};
