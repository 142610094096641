<template>
  <section class="section is-title-bar p-1 m-0">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <ul>
            <li v-show="showHome" class="is-size-6">
              <router-link v-if="items.length" to="/">Inicio</router-link>
              <span v-else>Inicio</span>
            </li>
            <li v-for="(item, key) in items" :key="key" class="is-size-6">
              <router-link v-if="item.to" href="#" :to="item.to">{{
                item.title
              }}</router-link>
              <span v-else>{{ item.title }}</span>
            </li>
          </ul>
        </div>
        <div class="level-item" v-if="$slots.buttonsleft">
          <div class="buttons is-left">
            <slot name="buttonsleft"></slot>
          </div>
        </div>
      </div>
      <div class="level-right" v-if="$slots.buttons">
        <div class="level-item">
          <div class="buttons is-right">
            <slot name="buttons"></slot>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    showHome: {
      type: Boolean,
      default: () => true,
    }
  },
};
</script>

<style lang="scss" scoped>
section.section.is-title-bar ul li:first-child {
  padding-left: 0;
}
section.section.is-title-bar ul li:not(:last-child):after {
  display: inline-block;
  content: "/";
  padding-left: 0.75rem;
}
section.section.is-title-bar ul li:last-child {
  padding-right: 0;
  font-weight: bold;
  color: #242424;
}
section.section.is-title-bar ul li {
  display: inline-block;
  padding: 0 0 0 0.75rem;
  font-size: 1.3rem;
  color: #7a7a7a;
}
</style>
