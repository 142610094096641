const base_path = "auth";
const AuthRoutes = [
  {
    path: `/${base_path}/signin`,
    name: "AuthSignIn",
    component: () =>
      import(/* webpackChunkName: "signin" */ "./pages/SignIn.vue"),
    meta: {
      layout: "login",
      title: "Iniciar Sesión",
    },
  },
  {
    path: `/${base_path}/profile`,
    name: "AuthProfile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "./pages/Profile.vue"),
    meta: {
      title: "Mi perfil",
    },
  },
];

export default AuthRoutes;
